<template>
  <LoaderWrapper :loading="getIsLoading">
    <div class="supervisor-gant">
      <GantControl
        ref="gantControl"
        :key-update-timer-quotas="keyUpdateTimerQuotas"
        :cleared-interval="clearInterval"
        @removeQuotas="removeQuotas"
        @confirmUpdateQuotas="confirmUpdateQuotas"
        @cancelUpdateQuotas="cancelUpdateQuotas"
      />

      <div class="supervisor-gant__container">
        <g-gantt-chart
          :key="key"
          :chart-start="getDateStart"
          :chart-end="getDateEnd"
          :grid="chart1.grid"
          :grid-size="chart1.gridSize"
          :hide-timeaxis="chart1.hideTimeaxis"
          :push-on-overlap="chart1.pushOnOverlap"
          snap-back-on-overlap
          :precision="chart1.precision"
          :is-magnetic="chart1.isMagnetic"
          :highlighted-hours="chart1.highlightedHours"
          :row-label-width="chart1.rowLabelWidth"
          :row-height="chart1.rowHeight"
          :theme="chart1.selectedTheme"
          :may-add="true"
          :is-show-overlay="showOverlay"
          bar-start-key="time_from"
          bar-end-key="time_to"
          @contextmenu-bar="onContextmenuBar($event)"
          @dragend-bar="handleMouseUpBar($event)"
          @drag-bar="onDrag($event)"
          @drag-to-right-bar="onDrag"
          @click-bar="handleClickBar"
        >
          <g-gantt-row
            v-for="row in rows"
            :key="row.label"
            :row-id="Number(row.id)"
            :label="row.label"
            :bars="row.bars"
            :active-bars="activeIds"
            :highlight-on-hover="chart1.highlightOnHover"
            @addNewBar="addNewBar"
          >
            <template #default="slotProps">
              <el-dropdown @command="handleCommand">
                <span class="el-dropdown-link">
                  <i class="el-icon-setting hidden gant__icon" />
                </span>

                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    :command="{
                      command: 'addQuota',
                      unload_line_id: slotProps.id,
                    }"
                  >
                    Добавить квоту
                  </el-dropdown-item>
                  <el-dropdown-item
                    :disabled="disabledDropdown(slotProps.id)"
                    :command="{
                      command: 'editQuota',
                      unload_line_id: slotProps.id,
                    }"
                  >
                    Редактировать квоты
                  </el-dropdown-item>
                  <el-dropdown-item
                    :disabled="disabledDropdown(slotProps.id)"
                    :command="{
                      command: 'deleteQuota',
                      unload_line_id: slotProps.id,
                    }"
                  >
                    Удалить квоты
                  </el-dropdown-item>
                  <el-dropdown-item
                    :command="{
                      command: 'deleteLine',
                      unload_line_id: slotProps.id,
                    }"
                  >
                    Удалить линию
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>

            <template #bar-label="{ bar }">
              <span class="d-f-a-space">
                <span class="d-f-a-start">
                  <img
                    src="@/assets/img/about/truck.png"
                    class="mr-1"
                    height="30"
                    width="40"
                  />
                  <span>{{ getBarLabel(bar) }}</span>
                </span>

                <span class="d-f-a-start">{{ getBarCulture(bar) }}</span>
                <span style="margin-left: 16px">{{ getBarExporter(bar) }}</span>
              </span>
            </template>
          </g-gantt-row>

          <g-gantt-row row-id="nullLine">
            <template #default="slotProps">
              <div class="gant__row" @click="add">
                <i class="el-icon-circle-plus show gant__icon" />
                {{ slotProps.label }}
              </div>
            </template>
          </g-gantt-row>
        </g-gantt-chart>
      </div>
    </div>
  </LoaderWrapper>
</template>

<script>
import { DATE_FORMAT_HOURS } from '@/constants/date'
import {
  DELETE_SUPERVISOR_QUOTA,
  FETCH_SUPERVISOR_QUOTAS_LINES,
  GET_DATE_INTERVAL_QUOTAS_SUPERVISOR,
  GET_HISTORY_QUOTAS_SUPERVISOR,
  GET_LOADINGS_SUPERVISOR_STATISTIC,
  POST_SUPERVISOR_QUOTAS_LINES,
  PUT_SUPERVISOR_QUOTA,
  SET_HISTORY_SUPERVISOR_QUOTA,
  SET_SUPERVISOR_QUOTA_NEW_DAY,
} from '@/store/actions'
import { GET_PREPARE_CULTURE_FROM_STATE } from '@/views/control/store/actions'
import { GET_SUPPLIERS_ALL } from '@/views/exporter/store/actions'
import { debounce, getDateToUnix, getTime, nowUnix } from '@/core'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import GGanttChart from '@/core/lib/components/GGanttChart'
import GGanttRow from '@/core/lib/components/GGanttRow'
import GantControl from '@/views/supervisor/components/GantControl'
import LoaderWrapper from '@/UI/loader/LoaderWrapper'

export default {
  name: 'Gant',
  components: {
    LoaderWrapper,
    GantControl,
    GGanttChart,
    GGanttRow,
  },
  props: {
    clearActive: {
      type: Boolean,
    },
    isShow: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    clearInterval: false,
    showOverlay: false,
    timeOffset: 400000,
    scrollLeft: 0,
    key: 1,
    timeoutId: null,
    activeIds: [],
    activeBars: [],
    isOverDay: true,
    chart1: {
      precision: 'day',
      pushOnOverlap: true,
      isMagnetic: false,
      grid: true,
      gridSize: 50,
      rowHeight: 60,
      rowLabelWidth: 50,
      hideTimeaxis: false,
      highlightOnHover: true,
      highlightedHours: [10],
      selectedTheme: 'white',
      listener: null,
    },
    rows: [],
    currentFragment: 0,
    keyUpdateTimerQuotas: 0,
    prepareRequestBars: [],
  }),
  computed: {
    ...mapGetters({
      getHistoryList: GET_HISTORY_QUOTAS_SUPERVISOR,
      getIsLoading: GET_LOADINGS_SUPERVISOR_STATISTIC,
      getPrepareCulture: GET_PREPARE_CULTURE_FROM_STATE,
      getIntervalDate: GET_DATE_INTERVAL_QUOTAS_SUPERVISOR,
      suppliers: GET_SUPPLIERS_ALL,
    }),
    getDateStart() {
      return this.getIntervalDate.dateStart
        ? this.getIntervalDate.dateStart
        : getTime(nowUnix(), DATE_FORMAT_HOURS)
    },
    getDateEnd() {
      return this.getIntervalDate.dateEnd
        ? this.getIntervalDate.dateEnd
        : getTime(nowUnix() + 8.64e7, DATE_FORMAT_HOURS)
    },
  },
  watch: {
    clearActive: {
      immediate: true,
      handler(newVal) {
        if (newVal === true) {
          this.showOverlay = false
          this.getSupervisorQuotasLines()
          this.activeIds = []
          this.activeBars = []
        }
      },
    },
    getIsLoading: {
      immediate: true,
      handler(newVal) {
        if (newVal === false && this.isShow) {
          this.showOverlay = true
        }
      },
    },
    getHistoryList(val) {
      if (val.length > 0) {
        this.showOverlay = false
        this.activeIds = []
        this.activeBars = []
        this.emitActiveBars()

        this.currentFragment = val.length - 1
        this.rows = JSON.parse(JSON.stringify(val[this.currentFragment]))
        this.key++
        this.$emit('quotaRows', this.rows)
      }
    },
  },
  mounted() {
    this.addHorizontalEvent()
  },
  updated() {
    this.addHorizontalEvent()

    setTimeout(() => {
      if (this.scrollLeft !== 0) {
        document.querySelector('.g-gantt-chart').scrollLeft = this.scrollLeft
      } else {
        const chartCenter =
          document.querySelector('.g-gantt-chart').getBoundingClientRect()
            .right / 2

        const leftPosition =
          document
            .querySelector('.g-grid-line-highlighted')
            .getBoundingClientRect().left -
          chartCenter -
          document
            .querySelector('.g-grid-line-highlighted')
            .getBoundingClientRect().width

        document.querySelector('.g-gantt-chart').scrollTo({
          left: leftPosition,
          behavior: 'smooth',
        })
      }
    }, 500)
  },
  methods: {
    ...mapActions({
      deleteSupervisorQuota: DELETE_SUPERVISOR_QUOTA,
      addQuotasLine: POST_SUPERVISOR_QUOTAS_LINES,
      updateQuotas: PUT_SUPERVISOR_QUOTA,
      getSupervisorQuotasLines: FETCH_SUPERVISOR_QUOTAS_LINES,
    }),
    ...mapMutations({
      setHistoryQuotas: SET_HISTORY_SUPERVISOR_QUOTA,
      addNewDay: SET_SUPERVISOR_QUOTA_NEW_DAY,
    }),
    disabledDropdown(id) {
      let bars = []

      this.rows.map(item => {
        if (String(item.id) === String(id)) {
          bars = item.bars
        }
      })

      return bars.length <= 0
    },
    add() {
      this.addQuotasLine()
    },
    addNewDayLocal() {
      this.addNewDay()
      this.setCurrentPositionToGant()
    },
    onDrag(e) {
      e.event.preventDefault()
      this.contextmenuY = e.event.clientY
      this.contextmenuX = e.event.clientX
      let { rowId, bar } = e

      const currentRow = this.rows.find(row => row.id === String(rowId))

      const oldBarTime = currentRow.bars.reduce(
        (accum, item) =>
          (accum =
            getDateToUnix(item.time_to) > accum
              ? getDateToUnix(item.time_to)
              : accum),
        0,
      )
      const oldBarTimeMin = currentRow.bars.reduce(
        (accum, item) =>
          (accum =
            getDateToUnix(item.time_from) > accum
              ? getDateToUnix(item.time_from)
              : accum),
        0,
      )

      let matchMaxFromAndPrevBarTimeTo = false

      if (getDateToUnix(bar.time_from) === oldBarTimeMin) {
        matchMaxFromAndPrevBarTimeTo = true
      }
      if (oldBarTimeMin <= getDateToUnix(bar.time_to)) {
        matchMaxFromAndPrevBarTimeTo = true
      }

      if (
        matchMaxFromAndPrevBarTimeTo &&
        oldBarTime >= getDateToUnix(this.getDateEnd) - this.timeOffset &&
        this.isOverDay
      ) {
        this.isOverDay = false
        this.addNewDayLocal()
        this.setCurrentPositionToGant()

        debounce(() => (this.isOverDay = true), 3000)
      }
    },
    handleMouseUpBar(event) {
      let idx = []
      const changedBars = [
        ...JSON.parse(JSON.stringify(Array.from(event.movedBars))).map(bar => {
          bar.time_from = getDateToUnix(bar.time_from) / 1000
          bar.time_to = getDateToUnix(bar.time_to) / 1000

          return bar
        }),
      ]

      changedBars.map(item => idx.push(item.id))

      if (this.prepareRequestBars?.length === 0) {
        this.prepareRequestBars = changedBars
      } else {
        this.prepareRequestBars = [
          ...this.prepareRequestBars.filter(item => !idx.includes(item.id)),
          ...changedBars,
        ]
      }

      ++this.keyUpdateTimerQuotas
    },
    updatePositionsBars() {
      // вот эта хрень нужна потому, что бэк требует поле именно truck_type_codes,
      // хотя возвращает он в итоге truck_types
      let structuredBars = this.prepareRequestBars.map(
        ({ truck_types, ...item }) => ({
          truck_type_codes: truck_types,
          ...item,
        }),
      )

      this.updateQuotas(structuredBars)
      this.setHistoryQuotas(this.rows)
      this.setCurrentPositionToGant()
      this.prepareRequestBars = []
    },
    onContextmenuBar(e) {
      e.event.preventDefault()
      this.contextmenuY = e.event.clientY
      this.contextmenuX = e.event.clientX
      this.showContextmenu = true

      if (this.contextmenuTimeout) {
        clearTimeout(this.contextmenuTimeout)
      }
      this.contextmenuTimeout = setTimeout(
        () => (this.showContextmenu = false),
        3000,
      )
    },
    addHorizontalEvent() {
      document
        .querySelector('.g-gantt-chart')
        .addEventListener('scroll', this.setScrollLeft)
    },
    addEventDelete() {
      window.addEventListener('keydown', this.removeActiveIds)
    },
    setScrollLeft(event) {
      this.scrollLeft = event.target.scrollLeft
    },
    removeActiveIds(event) {
      if (event.key === 'Backspace' && this.activeIds.length > 0) {
        this.$confirm('Вы хотите удалить выбранные квоты?', 'Внимание', {
          confirmButtonText: 'Да',
          cancelButtonText: 'Отмена',
          type: 'success',
        })
          .then(async () => {
            await this.deleteSupervisorQuota({ id: this.activeIds })

            this.$message({
              type: 'success',
              message: 'Правила вызова удалены',
              center: true,
            })
          })
          .catch(() => {})
      }
    },
    setCurrentPositionToGant() {
      this.key++
      this.$nextTick(() => {
        document.querySelector('.g-gantt-chart').scrollLeft = this.scrollLeft
      })
    },

    messageBeforeOpenHandle() {
      if (this.prepareRequestBars.length) {
        this.$confirm(
          'Вы открываете ручное редактирование, но не сохранили изменения',
          'Внимание',
          {
            confirmButtonText: 'Сохранить изменения',
            cancelButtonText: 'Продолжить без сохранения',
            type: 'warning',
          },
        )
          .then(() => {
            this.confirmUpdateQuotas()
            this.clearInterval = true
          })
          .catch(() => {
            this.cancelUpdateQuotas()
            this.clearInterval = true
          })
      }
    },
    async handleClickBar(event) {
      if (!this.timeoutId) {
        this.timeoutId = setTimeout(() => {
          this.timeoutId = null

          if (event.event.shiftKey) {
            this.activeIds.push(event.bar.id)
            this.activeBars.push(event.bar)

            document.addEventListener('keyup', this.shiftUp)
          }
        }, 300) // tolerance in ms
      } else {
        await this.messageBeforeOpenHandle()
        clearTimeout(this.timeoutId)
        this.timeoutId = null
        this.clearInterval = false

        if (this.activeIds.includes(event.bar.id)) {
          this.activeIds = []
          this.activeBars = []
        } else {
          this.activeIds = [event.bar.id]
          this.activeBars = [event.bar]
        }
        debounce(this.emitActiveBars, 500)
      }
    },
    handleCommand(command) {
      if (command.command === 'addQuota' || command.command === 'editQuota') {
        this.showOverlay = true
      }

      this.$emit('commandQuota', command, this.rows)
    },
    emitActiveBars() {
      if (this.activeBars.length > 0) {
        this.showOverlay = true
        this.$emit('emitActiveBars', this.activeBars, this.rows)
      }
    },
    getBarLabel(bar) {
      return bar?.buffer_quota || ''
    },
    getBarCulture(bar) {
      return this.getPrepareCulture[bar?.culture_id]?.name
        ? `${this.getPrepareCulture[bar?.culture_id]?.name} ${
            this.getPrepareCulture[bar?.culture_id]?.harvest_year
              ? this.getPrepareCulture[bar?.culture_id]?.harvest_year
              : ''
          }`
        : ''
    },
    getBarExporter(bar) {
      if (bar && bar?.suppliers_ids?.length > 0) {
        const exporter = this.suppliers.filter(
          item => item.id === bar?.suppliers_ids[0],
        )

        return exporter.length > 0 ? `${exporter[0]?.name}` : ''
      }

      return ''
    },
    addNewBar({ rowId, bar }) {
      this.showOverlay = true
      this.$emit('doubleCLikLine', rowId, bar, this.rows)
    },
    removeQuotas() {
      this.$confirm('Вы хотите удалить все квоты?', 'Внимание', {
        confirmButtonText: 'Да',
        cancelButtonText: 'Отмена',
        type: 'warning',
      })
        .then(() => {
          const removeIds = this.rows.reduce((accum, item) => {
            item.bars.forEach(bar => accum.push(bar.id))

            return accum
          }, [])

          this.deleteSupervisorQuota({ id: removeIds })
        })
        .catch(() => {})
    },
    shiftUp(e) {
      if (e.key === 'Shift') {
        this.emitActiveBars()
        document.removeEventListener('keyup', this.shiftUp)
      }
    },
    confirmUpdateQuotas() {
      this.updatePositionsBars()
    },
    cancelUpdateQuotas() {
      this.getSupervisorQuotasLines()
    },
  },
}
</script>

<style lang="sass">
$min-width-container: 48 * 24

.supervisor-gant
  flex: 1 0 auto
  background: #FFFFFF
  border: $border-default
  box-shadow: $box-shadow-default
  border-radius: 8px
  padding: 10px

  &__container
    position: relative

  .gant
    position: relative
    min-height: 300px
    width: 100%
    min-width: #{$min-width-container}px
    padding-top: 46px

    &__row
      border-bottom: 2px dotted $color-btn-gray
      display: flex
      justify-content: flex-start
      align-items: center
      position: relative
      min-height: 60px
      &:hover
        .hidden
          visibility: visible
          opacity: 1

      .hidden
        visibility: hidden
        opacity: 0
        transform: rotate(90deg)
        font-size: 24px
    &__icon
      font-size: 30px
      cursor: pointer
      color: $btn-orange
      transition: all .3s ease
      position: relative
      z-index: 5
      &:hover
        color: $color-black

    &__quota
      position: absolute
      top: 3px
      bottom: 3px
      background: #dddddd
      border-radius: 15px
      padding: 5px
      display: flex
      justify-content: space-between
      align-items: stretch
      overflow: hidden
      cursor: pointer
      border: 2px solid #dddddd
      transition: border .2s ease
      -webkit-touch-callout: none
      -webkit-user-select: none
      -khtml-user-select: none
      -moz-user-select: none
      -ms-user-select: none
      user-select: none

      &.active-quota
        border: 2px solid #5ebe78
      &.short
        .gant__quota-label
          display: block
          max-width: 95px

          span
            display: block
            font-size: 14px

        .gant__quota-icon
          font-size: 18px
          margin-right: 0
          margin-bottom: 5px

        .gant__culture
          margin-left: auto
          max-width: calc(100% - 95px)
          align-self: stretch
          text-align: right
          padding-left: 10px
          display: flex
          align-items: center
          font-size: 12px

    &__quota-label
      align-self: stretch
      display: flex
      justify-content: flex-start
      align-items: center
      max-width: 170px
      overflow: hidden

    &__quota-icon
      font-size: 40px
      margin-right: 5px

    &__culture
      margin-left: auto
      max-width: calc(100% - 170px)
      align-self: stretch
      text-align: right
      padding-left: 20px
      display: flex
      align-items: center

.mr-1
  margin-right: 4px
  min-width: 40px
.d-f-a-space
  display: flex
  justify-content: space-between
  align-items: center
.d-f-a-start
  display: flex
  justify-content: flex-start
  align-items: center
  margin-right: 30px
</style>
