var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("LoaderWrapper", { attrs: { loading: _vm.getIsLoading } }, [
    _c(
      "div",
      { staticClass: "supervisor-gant" },
      [
        _c("GantControl", {
          ref: "gantControl",
          attrs: {
            "key-update-timer-quotas": _vm.keyUpdateTimerQuotas,
            "cleared-interval": _vm.clearInterval,
          },
          on: {
            removeQuotas: _vm.removeQuotas,
            confirmUpdateQuotas: _vm.confirmUpdateQuotas,
            cancelUpdateQuotas: _vm.cancelUpdateQuotas,
          },
        }),
        _c(
          "div",
          { staticClass: "supervisor-gant__container" },
          [
            _c(
              "g-gantt-chart",
              {
                key: _vm.key,
                attrs: {
                  "chart-start": _vm.getDateStart,
                  "chart-end": _vm.getDateEnd,
                  grid: _vm.chart1.grid,
                  "grid-size": _vm.chart1.gridSize,
                  "hide-timeaxis": _vm.chart1.hideTimeaxis,
                  "push-on-overlap": _vm.chart1.pushOnOverlap,
                  "snap-back-on-overlap": "",
                  precision: _vm.chart1.precision,
                  "is-magnetic": _vm.chart1.isMagnetic,
                  "highlighted-hours": _vm.chart1.highlightedHours,
                  "row-label-width": _vm.chart1.rowLabelWidth,
                  "row-height": _vm.chart1.rowHeight,
                  theme: _vm.chart1.selectedTheme,
                  "may-add": true,
                  "is-show-overlay": _vm.showOverlay,
                  "bar-start-key": "time_from",
                  "bar-end-key": "time_to",
                },
                on: {
                  "contextmenu-bar": function ($event) {
                    return _vm.onContextmenuBar($event)
                  },
                  "dragend-bar": function ($event) {
                    return _vm.handleMouseUpBar($event)
                  },
                  "drag-bar": function ($event) {
                    return _vm.onDrag($event)
                  },
                  "drag-to-right-bar": _vm.onDrag,
                  "click-bar": _vm.handleClickBar,
                },
              },
              [
                _vm._l(_vm.rows, function (row) {
                  return _c("g-gantt-row", {
                    key: row.label,
                    attrs: {
                      "row-id": Number(row.id),
                      label: row.label,
                      bars: row.bars,
                      "active-bars": _vm.activeIds,
                      "highlight-on-hover": _vm.chart1.highlightOnHover,
                    },
                    on: { addNewBar: _vm.addNewBar },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (slotProps) {
                            return [
                              _c(
                                "el-dropdown",
                                { on: { command: _vm.handleCommand } },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "el-dropdown-link" },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "el-icon-setting hidden gant__icon",
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "el-dropdown-menu",
                                    {
                                      attrs: { slot: "dropdown" },
                                      slot: "dropdown",
                                    },
                                    [
                                      _c(
                                        "el-dropdown-item",
                                        {
                                          attrs: {
                                            command: {
                                              command: "addQuota",
                                              unload_line_id: slotProps.id,
                                            },
                                          },
                                        },
                                        [_vm._v(" Добавить квоту ")]
                                      ),
                                      _c(
                                        "el-dropdown-item",
                                        {
                                          attrs: {
                                            disabled: _vm.disabledDropdown(
                                              slotProps.id
                                            ),
                                            command: {
                                              command: "editQuota",
                                              unload_line_id: slotProps.id,
                                            },
                                          },
                                        },
                                        [_vm._v(" Редактировать квоты ")]
                                      ),
                                      _c(
                                        "el-dropdown-item",
                                        {
                                          attrs: {
                                            disabled: _vm.disabledDropdown(
                                              slotProps.id
                                            ),
                                            command: {
                                              command: "deleteQuota",
                                              unload_line_id: slotProps.id,
                                            },
                                          },
                                        },
                                        [_vm._v(" Удалить квоты ")]
                                      ),
                                      _c(
                                        "el-dropdown-item",
                                        {
                                          attrs: {
                                            command: {
                                              command: "deleteLine",
                                              unload_line_id: slotProps.id,
                                            },
                                          },
                                        },
                                        [_vm._v(" Удалить линию ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                        {
                          key: "bar-label",
                          fn: function ({ bar }) {
                            return [
                              _c("span", { staticClass: "d-f-a-space" }, [
                                _c("span", { staticClass: "d-f-a-start" }, [
                                  _c("img", {
                                    staticClass: "mr-1",
                                    attrs: {
                                      src: require("@/assets/img/about/truck.png"),
                                      height: "30",
                                      width: "40",
                                    },
                                  }),
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.getBarLabel(bar))),
                                  ]),
                                ]),
                                _c("span", { staticClass: "d-f-a-start" }, [
                                  _vm._v(_vm._s(_vm.getBarCulture(bar))),
                                ]),
                                _c(
                                  "span",
                                  { staticStyle: { "margin-left": "16px" } },
                                  [_vm._v(_vm._s(_vm.getBarExporter(bar)))]
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  })
                }),
                _c("g-gantt-row", {
                  attrs: { "row-id": "nullLine" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (slotProps) {
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "gant__row",
                              on: { click: _vm.add },
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "el-icon-circle-plus show gant__icon",
                              }),
                              _vm._v(" " + _vm._s(slotProps.label) + " "),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              2
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }